import React from "react";

const Contact = () => {
  const [loading, setloading] = React.useState(false)
  async function submitForm(e) {
    let frm = e.target.form;
    if (!frm) return console.log("misconfigured");
    const action = frm.getAttribute("action");
    const method = frm.getAttribute("method") || "post";
    let payload = {};
    let errors = [];
    frm.querySelectorAll("[name]").forEach((fld) => {
      if (!fld.validity.valid) errors.push(fld);
      if (["checkbox", "radio"].indexOf(fld.type) === -1) {
        payload[fld.name] = fld.value;
        return;
      }
      if (fld.checked) payload[fld.name] = fld.value;
    });
    if (errors.length) {
      errors[0].focus();
      return false;
    }
    localStorage.setItem("data",JSON.stringify(payload.name))
    setloading(true)
    fetch(`https://backend.iclimbs.com${action}`, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setloading(false);
        window.location.replace(`/thankyou`);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <>
      <form
        method={"post"}
        action={"/api/v1/contact/landingpage/new"}
        className="contact"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <section className="py-10 ">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="grid lg:grid-cols-2 grid-cols-1">
              <div className="lg:mb-0 mb-10">
                <div className="group w-full h-full">
                  <div className="relative">
                    <img
                      src="/assets/images/contact/contact.webp"
                      alt="Iclimbs ContactUs"
                      className="w-full h-full lg:rounded-l-2xl rounded-2xl bg-blend-multiply bg-indigo-700"
                    />
                    <h1 className="font-manrope text-white text-4xl font-bold leading-10 absolute top-11 left-11">
                      Contact us
                    </h1>
                    <div className="absolute bottom-0 w-full lg:p-11 p-5">
                      <div className="bg-white rounded-lg p-6 block">
                        <a href="tel:+917015520689" className="flex items-center mb-6">
                          <img src="/assets/images/Icons/call.svg" alt="Iclimbs Contact No" className="w-7"/>
                          <h5 className="text-black text-base font-normal leading-6 ml-5">
                          7015520689
                          </h5>
                        </a>
                        <a
                          href="mailto:contact@iclimbs.com"
                          className="flex items-center mb-6"
                        >
                          <img src="/assets/images/Icons/mail.svg" alt="IClimbs Email" className="w-7" />
                          <h5 className="text-black text-base font-normal leading-6 ml-5">
                            contact@iclimbs.com
                          </h5>
                        </a>
                        <a href="https://maps.app.goo.gl/pcP6jGSJUtKvcSnW9" className="flex items-center">
                          <img src="/assets/images/Icons/location.svg" alt="IClimbs Location" className="w-7" />
                          <h5 className="text-black text-base font-normal leading-6 ml-5 text-balance">
                            Plot-269, Phase IV, Udyog Vihar, Sector 18,
                            Gurugram, Haryana 122015
                          </h5>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-5 lg:rounded-r-2xl rounded-2xl">
                <h2 className="text-[#0131B9] font-manrope text-4xl font-semibold leading-10 mb-3">
                  We'd love to hear from you!
                </h2>
                <div>
                  <frfs-text
                    label="Your Name"
                    name="name"
                    placeholder="Your Name"
                    required="true"
                    editable="true"
                  />
                </div>
                <div>
                  <frfs-email
                    label="Work Email"
                    name="email"
                    placeholder="Work Email"
                    required="true"
                    editable="true"
                  />
                </div>
                <div>
                  <frfs-tel
                    label="Phone No"
                    name="phone"
                    placeholder="Phone No"
                    required="true"
                    editable="true"
                    cn="in"
                  />
                </div>
                <div>
                  <frfs-select
                    name="service"
                    required="true"
                    editable="true"
                    api="https://backend.iclimbs.com/api/v1/purpose/list"
                    label="Select A Purpose"
                  />
                </div>
                <div
                  style={{
                    height: "auto",
                    width: "100%",
                    fontSize: "14px",
                    marginBottom: "3px",
                    textTransform: "capitalize",
                  }}
                  className="col-span-2"
                >
                  <label htmlFor="message">
                    I'm Interested in More about...{" "}
                    <span style={{ color: "red" }}>*</span>{" "}
                  </label>
                  <br />
                  <textarea
                    name="message"
                    rows="5"
                    required={true}
                    style={{ width: "100%" }}
                  ></textarea>
                </div>
                <div className="w-48 m-auto text-sm">
                  <e-captcha path="/images/captcha/" name="captcha" />
                </div>
                <div className="text-center py-2 ">
                  <button
                    className="py-3 px-6 bg-[#0131B9] hover:bg-[#2282F1] text-sm  text-white font-semibold rounded-lg"
                    onClick={submitForm}
                    disabled={loading}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </>
  );
};

export default Contact;
