import React from "react";
import "./Journey.css";

const Journey = () => {
  const details = [
    {
      link: "/assets/images/tehnologyinsights/web.webp",
      alt: "Web Development",
      heading1: "Web Development",

    },
    {
      link: "/assets/images/tehnologyinsights/app.webp",
      alt: "App Development",
      heading1: "App Development",

    },
    {
      link: "/assets/images/tehnologyinsights/ui.webp",
      alt: "UI/UX",
      heading1: "UI & UX",

    },
    {
      link: "/assets/images/tehnologyinsights/ecommerce.webp",
      alt: "Ecommerce Development",
      heading1: "Ecommerce Development",

    },
    {
      link: "/assets/images/tehnologyinsights/cloud.png",
      alt: "Cloud Computing",
      heading1: "Cloud Computing",

    },
    {
      link: "/assets/images/tehnologyinsights/cyber.png",
      alt: "Cyber Security",
      heading1: "Cyber Security",

    },
  ];

  return (
    <div className="services py-12  m-auto bg-[#E3EBFF] mt-8">
      <div className="w-[90%] m-auto">
        <div className="m-auto w-[92%]">
          <p className="text-4xl">Our Services</p>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 xs:grid-cols-1 gap-10 text-center mt-10 lg:w-4/5 xs:w-full m-auto">

          {details.map((el, index) => (
            <div
              key={index}
            >
              <img src={el.link} alt={el.alt} />
              <div className="expanded-content mt-5 text-center">
                <h1 className="font-semibold">{el.heading1}</h1>
              </div>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

export default Journey;
